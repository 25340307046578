<template>
  <div
    v-if="
      getUser.role.slug === 'charge-daffaires' ||
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'responsable-relation-client'
    "
  >
    <b-card>
      <b-table
        ref="refInvoiceListTable"
        :items="logs"
        responsive
        :fields="historyTableColumns"
        primary-key="id"
        :busy="isPaymentHistoryLoading"
        show-empty
        empty-text="Pas d'historique"
        class="position-relative"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle" /> &nbsp;
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- Column: next_payment_date -->
        <template #cell(month_salary)="data">
          <p class="text-capitalize">''
            {{ data.item.month_salary }}
          </p>
        </template>
        <template #cell(employee)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.employee.profile_image"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.employee.full_name }}
            </span>
            <small class="text-success font-small-3">{{
              data.item.employee.phone_number
            }}</small>
          </b-media>
        </template>
        <!-- Column: Invoice Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveOfferStatusVariantForHistory(
              data.item.status
            )}`"
            class="font-small-3"
          >
            {{
              data.item.status === 0
                ? "À payer"
                : "Payé le " +
                  moment(data.item.salary_paid_date)
                    .locale("fr")
                    .format("dddd LL")
            }}
          </b-badge>
        </template>

        <!-- Column: next_payment_date -->
        <template #cell(actions)="data">
          <div
            v-if="data.item.status === 0"
            class="text-nowrap"
          >
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="25"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="sendPaiementLinkToCustoomer(data.item.id)">
                <feather-icon icon="LinkIcon" />
                <span
                  class="align-middle ml-50"
                >Envoyer le lien de paiement</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>

</template>

<script>
import {
  BAlert, BAvatar, BBadge, BButton,
  BCard,
  BCardBody,
  BCardHeader, BCol, BDropdown, BDropdownItem,
  BForm,
  BFormGroup, BFormInput,
  BFormSelect, BLink, BMedia, BRow,
  BSpinner,
  BTab, BTable,
  BTableLite,
  BTabs, BTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { alphaNum, required } from '@core/utils/validations/validations'
import { avatarText } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ListOfTerminatedEmployees from '@/views/order/recurring/details/TerminatedEmployees'

export default {
  name: 'PaymentLogs',
  components: {
    BCard,
    BTab,
    BTableLite,
    BTabs,
    // BFormTextarea,
    BFormSelect,
    BSpinner,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardBody,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    // BPagination,
    BTooltip,
    vSelect,
    flatPickr,

    ListOfTerminatedEmployees,
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      directOrder: null,
      required,
      propositions: [],
      employeeOptions: [],
      managedEmployee: [],
      history: [],
      alphaNum,
      isMakingPropositionLoading: false,
      isProposedEmployeesLoading: false,
      isPaymentHistoryLoading: false,
      payload: {
        salary: '',
        search_employee: '',
        employee_id: '',
      },
      selectedEmployee: {},
      changingEmployee: {
        salary: '',
        employee_id: '',
      },
      currentProposition: {},

      historyTableColumns: [
        {
          key: 'month_salary', label: 'Mois ', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'employee', label: 'Employé', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'employee_salary_amount', label: 'Salaire', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'ylomi_direct_fees', label: 'Commission', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'total_amount_to_paid', label: 'Total', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'status', label: 'Status', sortable: true, class: 'font-medium-1',
        },
        { key: 'actions' },
      ],
      avatarText,
      terminateEmployeePayload: {
        date: null,
      },
      isTerminateEmployeeContratLoading: false,
      deployEmployeePayload: {
        employee_deployment_date: null,
      },
      isDeployEmployeeLoading: false,
      currentDate: null,
      isPageLoading: true,
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
  },

  watch: {},

  created() {
    const today = new Date()
    // eslint-disable-next-line no-multi-assign
    this.terminateEmployeePayload.date = this.deployEmployeePayload.employee_deployment_date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
    if (JSON.parse(localStorage.getItem('directOrder')) && JSON.parse(localStorage.getItem('directOrder')).id === this.$route.params.id) {
      this.directOrder = JSON.parse(localStorage.getItem('directOrder'))
      this.history = this.directOrder.direct_salary_payments.reverse()
      this.applyGetServiceEmployeesAction()
      this.isPageLoading = false
    } else {
      this.getDirectOrderAction(this.$route.params.id)
        .then(response => {
          this.directOrder = response.data
          this.history = this.directOrder.direct_salary_payments.reverse()
          this.applyGetServiceEmployeesAction()
          this.isPageLoading = false
        })
        .catch(error => {
          console.log(error)
        })
    }
  },

  methods: {
    ...mapActions('services', ['getServiceEmployeesAction']),

    ...mapActions('orders', [
      'makeEmployeeDeploymentAction',
      'terminateEmployeeContractAction',
      'markDirectOrderAsFinishedAction',
      'deleteDirectOrderPropositionAction',
      'makePropositionAction',
      'deleteDirectOrderAction',
      'getDirectOrderAction',
      'getDirectOrderProposedEmployeesAction',
      'getDirectOrdersAction',
      'modifyPropositionAction',
    ]),

    getProposition(item) {
      // eslint-disable-next-line no-multi-assign
      console.log(item, item.id)
      this.selectedEmployee = item.employee
      this.currentProposition = item
      // eslint-disable-next-line prefer-destructuring
      const percentage = this.directOrder.offer_type.percentage
      this.changingEmployee.salary = Math.round((item.salary / (1 + (percentage / 100))))
    },

    modifyOrder(id) {
      this.$router.push({
        name: 'admin-direct-order-modify',
        params: { id },
      })
    },

    sendPaiementLinkToCustoomer(id) {
      console.log(this.directOrder)
      window.open(
        `https://wa.me/${this.directOrder.user.phone_number}?text=NOs salutations!  La date du  paiement de la prestation de votre ${this.directOrder.recurring_service.name} *${this.directOrder.actif_employee.employee.full_name}* étant aujourdhui, merci de cliquer sur lien ci dessous pour effectuer le paiement. *https://ylomi.net/direct/${this.directOrder.id}/payment/${id}*`,
      )
    },

    applyGetServiceEmployeesAction() {
      this.getServiceEmployeesAction(
        this.directOrder.recurring_service.id,
      ).then(response => {
        // console.log(response)
        if (response.data.length === 0) {
          this.employeeOptions.push({
            value: '',
            disabled: true,
            text: "Vous n'avez pas d'employé fournissant ce service",
          })
        } else {
          response.data.forEach(element => {
            if (
              element.phone_number === this.directOrder.employee_phone_number
            ) {
              this.managedEmployee.push(element)
              this.payload.salary = this.directOrder.budget
            }
            this.employeeOptions.push(element)
          })
        }
      })
    },

    applyGetDirectOrderProposedEmployeesAction() {
      this.isProposedEmployeesLoading = true
      this.getDirectOrderProposedEmployeesAction(this.directOrder.id)
        .then(response => {
          this.propositions = response.data
          this.isProposedEmployeesLoading = false
        })
        .catch(error => {
          this.isProposedEmployeesLoading = false

          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDeleteDirectOrderAction() {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette demande?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderAction(this.directOrder.id)
          // eslint-disable-next-line no-return-await
          return await this.getDirectOrdersAction()
        },
      })
        .then(response => {
          if (response.value) {
            localStorage.removeItem('directOrder')
            this.$router.push({ name: 'admin-direct-orders' })
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'demande supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDeleteDirectOrderPropositionAction(directOrderId, employeeId) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette proposition ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderPropositionAction({
            directOrderId,
            employeeId,
          })
          // eslint-disable-next-line no-return-await
          return await this.applyGetDirectOrderProposedEmployeesAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Offre supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyModifyPropositionAction() {
      console.log(this.selectedEmployee)
      this.$refs.modifyPropositionForm.validate().then(success => {
        if (success) {
          this.isMakingPropositionLoading = true
          /* this.changingEmployee.search_employee = this.directOrder.search_employee
            ? 1
            : 0 */
          // eslint-disable-next-line eqeqeq
          if (this.directOrder.search_employee == 1) {
            const comission = this.directOrder.offer_type.name === 'Offre confort'
            // eslint-disable-next-line radix
              ? (parseInt(this.changingEmployee.salary) * 10) / 100
            // eslint-disable-next-line radix
              : parseInt(this.changingEmployee.salary * 15) / 100
            // eslint-disable-next-line radix
            this.changingEmployee.salary = Math.round(parseInt(this.changingEmployee.salary) + comission)
          }
          this.changingEmployee.employee_id = this.selectedEmployee.id

          console.log({
            payload: this.changingEmployee,
            id: this.directOrder.id,
            proposedEmployeeId: this.currentProposition.id,
          })

          this.modifyPropositionAction({
            payload: this.changingEmployee,
            id: this.directOrder.id,
            proposedEmployeeId: this.currentProposition.id,
          })
            .then(() => {
              this.getDirectOrderAction(this.directOrder.id).then(
                async response => {
                  this.directOrder = response.data
                  localStorage.setItem(
                    'directOrder',
                    JSON.stringify(this.directOrder),
                  )
                  this.isMakingPropositionLoading = false
                  await this.applyGetDirectOrderProposedEmployeesAction()
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Succès',
                        icon: 'CheckIcon',
                        text: "Proposition d'employée modifié avec succès.",
                        variant: 'success',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )

                  this.$bvModal.hide('modal-modify-proposition')
                  this.changingEmployee = {
                    salary: '',
                    employee_id: '',
                  }
                },
              )
              this.getDirectOrdersAction()
            })
            .catch(error => {
              // console.log(error)
              this.isMakingPropositionLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },

    applyMakePropositionAction() {
      this.$refs.newPropositionForm.validate().then(success => {
        if (success) {
          this.isMakingPropositionLoading = true
          this.payload.search_employee = this.directOrder.search_employee
            ? 1
            : 0
          // eslint-disable-next-line eqeqeq
          if (this.directOrder.search_employee == 1) {
            const comission = this.directOrder.offer_type.name === 'Offre confort'
            // eslint-disable-next-line radix
              ? (parseInt(this.payload.salary) * 10) / 100
            // eslint-disable-next-line radix
              : parseInt(this.payload.salary * 15) / 100
            // eslint-disable-next-line radix
            this.payload.salary = Math.round(parseInt(this.payload.salary) + comission)
          }

          this.makePropositionAction({
            payload: this.payload,
            id: this.directOrder.id,
          })
            .then(() => {
              this.getDirectOrderAction(this.directOrder.id).then(
                async response => {
                  this.directOrder = response.data
                  localStorage.setItem(
                    'directOrder',
                    JSON.stringify(this.directOrder),
                  )
                  this.isMakingPropositionLoading = false
                  await this.applyGetDirectOrderProposedEmployeesAction()
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Succès',
                        icon: 'CheckIcon',
                        text: 'Employé proposé avec succès',
                        variant: 'success',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )

                  this.$bvModal.hide('modal-new-proposition')
                  this.$bvModal.hide('modal-new-proposition-2')
                  this.payload = {
                    salary: '',
                    search_employee: '',
                    employee_id: '',
                  }
                },
              )
              this.getDirectOrdersAction()
            })
            .catch(error => {
              // console.log(error)
              this.isMakingPropositionLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },

    applyDeployEmployeeContractAction() {
      this.isDeployEmployeeLoading = true
      this.$swal({
        title: "Déployement d'employé",
        text: 'Êtes-vous sûr de vouloir déployer cet employé ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.makeEmployeeDeploymentAction({
          orderId: this.directOrder.id,
          payload: this.deployEmployeePayload,
        }),
      })
        .then(response => {
          this.isDeployEmployeeLoading = false
          console.log(response)
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.getDirectOrderAction(this.directOrder.id)
              .then(result => {
                localStorage.setItem('directOrder', JSON.stringify(result.data))
                this.$bvModal.hide('modal-deploy-employee')
                this.$router.go()
              })
          }
        })
        .catch(error => {
          this.isDeployEmployeeLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyTerminateEmployeeContractAction() {
      this.isTerminateEmployeeContratLoading = true
      this.$swal({
        title: 'Résilier le contrat employé',
        text: "Êtes-vous sûr de vouloir résilier ce contrat de l'employé actif ?",
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.terminateEmployeeContractAction({
          orderId: this.directOrder.id,
          payload: this.terminateEmployeePayload,
        }),
      })
        .then(response => {
          this.isTerminateEmployeeContratLoading = false
          // console.log(response)
          if (response.value) {
            this.directOrder = response.value.data
            this.applyGetDirectOrderProposedEmployeesAction()
            localStorage.setItem(
              'directOrder',
              JSON.stringify(this.directOrder),
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$bvModal.hide('modal-terminate-employee-contrat')
            this.getDirectOrdersAction()
          }
        })
        .catch(error => {
          this.isTerminateEmployeeContratLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyMarkDirectOrderAsFinished() {
      this.$swal({
        title: 'Clôturer le dossier',
        text: 'Êtes-vous sûr de vouloir clôturer le dossier ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.markDirectOrderAsFinishedAction(this.directOrder.id),
      })
        .then(response => {
          console.log(response)
          if (response.isConfirmed) {
            this.directOrder = response.value.data
            localStorage.setItem(
              'directOrder',
              JSON.stringify(this.directOrder),
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Contrat résilié',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.getDirectOrdersAction()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    resolveOfferStatusVariant(employeeId) {
      if (this.directOrder.actif_employee_id != null) {
        if (employeeId === this.directOrder.actif_employee_id) return 'success'
        return 'info'
      }
      return 'info'
    },

    resolveOfferStatusVariantForHistory(historyStatus) {
      if (historyStatus === 1) {
        return 'success'
      }
      return 'info'
    },

    resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'danger'
      if (status === 0 && placementCostPaid === false && searchEmployee === true) return 'danger'
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },

    resolveStatusText: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'Résilié'
      if (status === 0 && placementCostPaid === false && searchEmployee === true) return 'En attente des frais de placement'
      if (status === 0) return 'En attente'
      if (status === 1) return 'Employé proposé'
      if (status === 2) return 'Contract émis'
      if (status === 3) return 'Contract approuvé'
      if (status === 4) return 'Actif'
      return 'Terminée'
    },
    hideModal() {
      this.$bvModal.hide('modal-new-proposition')
      this.payload = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
    hideModal2() {
      this.$bvModal.hide('modal-new-proposition-2')
      this.payload = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
    hidePropositionModificationModal() {
      this.$bvModal.hide('modal-modify-proposition')
      this.changingEmployee = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
    openContractInNewTab(contractFile) {
      window.open(`${contractFile}`)
    },
  },
}
</script>

<style scoped>

</style>
