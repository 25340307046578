<template>
  <div v-if="
    getUser.role.slug === 'charge-daffaires' ||
    getUser.role.slug === 'super-administrateur' ||
    getUser.role.slug === 'responsable-relation-client'
  ">
    <b-card>
      <b-table ref="refInvoiceListTable" :items="listOfTerminatedEmployees" responsive
        :fields="terminatedEmployeesColumns" primary-key="id" :busy="isTerminatedEmployees" show-empty
        empty-text="Liste vide" class="position-relative">
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle" /> &nbsp;
            <strong>Chargement...</strong>
          </div>
        </template>

        <!-- Column: Client -->
        <template #cell(profile_image)="data">
          <div v-if="data.item.employee != null">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar rounded :text="avatarText(data.item.employee.full_name)" size="42"
                  :src="data.item.employee.profile_image" />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.employee.full_name }}
              </span>
              <small class="text-success font-small-3">
                {{ data.item.employee.phone_number }}</small>
            </b-media>
          </div>
          <span v-else>
            -----------------
          </span>

        </template>

        <!-- Column: Employee contract started date -->
        <template #cell(employee_contract_started_date)="data">
          <span>
            {{ moment(data.item.employee_contract_started_date).locale('fr').format('dddd DD MMMM YYYY') }}
          </span>
        </template>

        <!-- Column: Employee contract startended date -->
        <template #cell(employee_contract_end_date)="data">
          <span>
            {{ moment(data.item.employee_contract_end_date).locale('fr').format('dddd DD MMMM YYYY') }}
          </span>
        </template>
      </b-table>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>

</template>

<script>
import {
  BTable,
  BMedia,
  BAvatar,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BMedia,
    BAvatar,
    BSpinner,
    BCard,
  },
  data() {
    return {
      listOfTerminatedEmployees: [],
      isTerminatedEmployees: false,
      terminatedEmployeesColumns: [
        { key: 'profile_image', label: 'Identité', sortable: true, class: 'font-medium-1' },
        { key: 'employee_contract_started_date', label: 'Date de début du contrat', sortable: true, class: 'font-medium-1' },
        { key: 'employee_contract_end_date', label: 'Date de fin du contrat', sortable: true, class: 'font-medium-1' },
      ],
      avatarText,
    }
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },

  watch: {},

  created() {
    this.orderId = this.$route.params.id
    // console.log(this.orderId)
    this.applyGetTerminatedEmployeesAction()
  },

  methods: {
    ...mapActions('orders', ['getTerminatedEmployeesAction']),

    applyGetTerminatedEmployeesAction() {
      this.isTerminatedEmployees = true
      this.getTerminatedEmployeesAction(this.$props.orderId)
        .then(response => {
          this.isTerminatedEmployees = false
          // console.log(response)
          this.listOfTerminatedEmployees = response.data
        })
        .catch(error => {
          this.isTerminatedEmployees = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
  },
}
</script>
