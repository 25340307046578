<template>
  <div
    v-if="
      getUser.role.slug === 'charge-daffaires' ||
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'responsable-relation-client'
    "
  >
    <b-card>
      <b-row>
        <b-col cols="12">
          <div class="mb-2">
            <!-- for new proposition -->
            <b-button
              v-if="directOrder.search_employee"
              :disabled="directOrder.status === 4 || directOrder.status === 5"
              variant="primary"
              @click="showEmployeePropositionModal()"
            >
              <span class="text-nowrap font-medium-1">Nouvelle proposition</span>
            </b-button>

            <!-- for apply the employee to manage -->
            <b-button
              v-else
              v-b-modal.modal-new-proposition-2
              :disabled="
                directOrder.status !== 0 && directOrder.status !== 1
              "
              variant="primary"
            >
              <span class="text-nowrap">Affecter l'employé(e)</span>
            </b-button>

            <!-- New Proposition Modal -->
            <b-modal
              v-if="directOrder.search_employee"
              id="modal-new-proposition"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              :title="`Proposition d'employé`"
            >
              <validation-observer
                #default="{}"
                ref="newPropositionForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal"
                  @submit.prevent="applyMakePropositionAction"
                >
                  <b-form-group
                    label="Choisissez un employé"
                    label-for="role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="pro"
                      rules="required"
                    >
                      <b-form-select
                        v-if="
                          serviceEmployees.length === 1 &&
                            serviceEmployees[0].disabled
                        "
                        id="employee_id"
                        v-model="payload.employee_id"
                        :options="serviceEmployees"
                      />

                      <v-select
                        v-else
                        id="role"
                        v-model="selectedEmployee"
                        :reduce="(employee) => employee"
                        label="full_name"
                        :options="serviceEmployees"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          Chargement...
                        </span>
                        <template
                          #option="{
                            comment,
                            employee,
                          }"
                        >
                          <b-row>
                            <b-col md="3">
                              <b-avatar
                                size="80px"
                                rounded
                                :src="employee.profile_image"
                              />
                            </b-col>
                            <b-col md="9">
                              <b-row>
                                <b-col>
                                  <span class="text-white">
                                    {{ employee.full_name }}
                                  </span>
                                </b-col>
                                <b-col>
                                  <span class="text-white">
                                    {{ employee.age }} ans
                                  </span>
                                </b-col>
                              </b-row>
                              <b-row class="">
                                <b-col>
                                  <span class="text-white">
                                    {{ employee.marital_status }}
                                  </span>
                                </b-col>
                                <b-col>
                                  <span class="text-white">{{
                                    employee.address
                                  }}</span>
                                </b-col>
                              </b-row>

                              <b-row class="">
                                <b-col>
                                  <span class="text-white">
                                    Commentaire: {{ comment }}
                                  </span>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </template>
                      </v-select>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <validation-provider
                    #default="{ errors }"
                    name="prix"
                    rules="required"
                  >
                    <b-form-group
                      label="Budget du client"
                      label-for="designation"
                    >
                      <b-form-input
                        id="salary"
                        v-model="customerBudget"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="prix"
                    rules="required"
                  >
                    <b-form-group
                      label="Salaire de l'employé"
                      label-for="designation"
                    >
                      <b-form-input
                        id="salary"
                        v-model="employee_amount"
                        :readonly="getUser.role.slug === 'charge-daffaires'"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="prix"
                    rules="required"
                  >
                    <b-form-group
                      :label="`Commission ylomi  soit ${taux} %`"
                      label-for="designation"
                    >
                      <b-form-input
                        id="salary"
                        v-model="ylomi_amount"
                        readonly
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isMakingPropositionLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isMakingPropositionLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Envoyer</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>

            <!-- Get employee to be managed Modal -->
            <b-modal
              v-else
              id="modal-new-proposition-2"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              :title="`Affectation d'employé pour la demande de ${directOrder.recurring_service.name}`"
            >
              <validation-observer
                #default="{}"
                ref="newPropositionForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal2"
                  @submit.prevent="applyMakePropositionAction"
                >
                  <b-form-group
                    :label="`Employé(e) à gérer, fournissant le service de ${directOrder.recurring_service.name} `"
                    label-for="role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="pro"
                      rules="required"
                    >
                      <b-form-select
                        v-if="
                          serviceEmployees.length === 1 &&
                            serviceEmployees[0].disabled
                        "
                        id="employee_id"
                        v-model="payload.employee_id"
                        :options="serviceEmployees"
                      />
                      <v-select
                        v-else
                        id="role"
                        v-model="payload.employee_id"
                        :reduce="(employee) => employee.id"
                        label="full_name"
                        :options="managedEmployee"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          Chargement...
                        </span>
                        <template #option="{ full_name }">
                          <span class="text-info">
                            {{ full_name }}
                          </span>
                        </template>
                      </v-select>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="prix"
                    rules="required"
                  >
                    <b-form-group
                      label="Salaire de l'employé"
                      label-for="designation"
                    >
                      <b-form-input
                        id="salary"
                        v-model="payload.salary"
                        readonly
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isMakingPropositionLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isMakingPropositionLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Envoyer</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>

            <!-- Deploy Employee Modal -->
            <b-modal
              id="modal-deploy-employee"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              title="Déploiement d'employé"
            >
              <validation-observer
                #default="{}"
                ref="deployEmployeeForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hideModal"
                  @submit.prevent="applyDeployEmployeeContractAction()"
                >
                  <b-form-group
                    label="Date de déploiement"
                    label-for="role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="date de déploiement"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="deployEmployeePayload.employee_deployment_date"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isDeployEmployeeLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isDeployEmployeeLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Déployer l'employé</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>

            <!-- Modify Proposition Modal -->
            <b-modal
              id="modal-modify-proposition"
              cancel-variant="outline-secondary"
              hide-footer
              centered
              no-close-on-backdrop
              no-close-on-esc
              :title="`Modifier une proposition`"
            >
              <validation-observer
                #default="{}"
                ref="modifyPropositionForm"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  autocomplete="off"
                  @reset.prevent="hidePropositionModificationModal"
                  @submit.prevent="applyModifyPropositionAction"
                >
                  <b-form-group
                    label="Choisissez un employé"
                    label-for="role"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="pro"
                      rules="required"
                    >
                      <b-form-select
                        v-if="
                          serviceEmployees.length === 1 &&
                            serviceEmployees[0].disabled
                        "
                        id="employee_id"
                        v-model="selectedEmployee"
                        :options="serviceEmployees"
                      />
                      <v-select
                        v-else
                        id="role"
                        v-model="selectedEmployee"
                        :reduce="(employee) => employee"
                        label="full_name"
                        :options="serviceEmployees"
                      >
                        <span
                          slot="no-options"
                          @click="$refs.select.open = false"
                        >
                          Chargement...
                        </span>
                        <template
                          #option="{
                            comment,
                            employee,
                          }"
                        >
                          <b-row>
                            <b-col md="3">
                              <b-avatar
                                size="80px"
                                rounded
                                :src="employee.profile_image"
                              />
                            </b-col>
                            <b-col md="9">
                              <b-row>
                                <b-col>
                                  <span class="text-info">
                                    {{ employee.full_name }}
                                  </span>
                                </b-col>
                                <b-col>
                                  <span class="text-info">
                                    {{ employee.age }} ans
                                  </span>
                                </b-col>
                              </b-row>
                              <b-row class="">
                                <b-col>
                                  <span class="text-info">
                                    {{ employee.marital_status }}
                                  </span>
                                </b-col>
                                <b-col>
                                  <span class="text-info">{{
                                    employee.address
                                  }}</span>
                                </b-col>
                              </b-row>

                              <b-row class="">
                                <b-col>
                                  <span class="text-info">
                                    Commentaire: {{ comment }}
                                  </span>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </template>

                        <template #selected-option="employee">
                          {{ employee.full_name }}
                        </template>
                      </v-select>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="prix"
                    rules="required"
                  >
                    <b-form-group
                      label="Budget du client"
                      label-for="designation"
                    >
                      <b-form-input
                        id="salary"
                        v-model="customerBudget"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="prix"
                    rules="required"
                  >
                    <b-form-group
                      label="Salaire de l'employé"
                      label-for="designation"
                    >
                      <b-form-input
                        id="salary"
                        v-model="employee_amount"
                        :readonly="getUser.role.slug === 'charge-daffaires'"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    #default="{ errors }"
                    name="prix"
                    rules="required"
                  >
                    <b-form-group
                      :label="`Commission ylomi  soit ${taux} %`"
                      label-for="designation"
                    >
                      <b-form-input
                        id="salary"
                        v-model="ylomi_amount"
                        readonly
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      :disabled="isMakingPropositionLoading"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      <div v-if="isMakingPropositionLoading">
                        <span> Chargement ... </span>
                        <b-spinner small />
                      </div>
                      <span v-else>Modifier</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="outline-secondary"
                    >
                      Annuler
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </b-modal>
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="refInvoiceListTable"
        :items="propositions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isProposedEmployeesLoading"
        show-empty
        empty-text="Liste vide"
        class="position-relative"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle" /> &nbsp;
            <strong>Chargement...</strong>
          </div>
        </template>

        <!-- Column: Invoice Status -->
        <template #cell(is_rejected)="data">
          <b-badge
            pill
            :variant="`light-${resolveOfferStatusVariant(data.item.id)}`"
            class="text-capitalize font-small-3"
          >
            {{
              directOrder.actif_employee_id !== null &&
                data.item.id === directOrder.actif_employee_id
                ? "Employé accepté"
                : "-------------"
            }}
          </b-badge>
        </template>

        <!-- Salaire -->
        <template #cell(salary)="data">
          <span>
            {{ data.item.employee_amount }}
          </span>
        </template>

        <!-- Commission -->
        <template #cell(commission)="data">
          <span>
            {{
              data.item.ylomi_amount
            }}
          </span>
        </template>
        <template #cell(total_amount)="data">
          <span>
            {{

              data.item.employee_amount + data.item.ylomi_amount
            }}
          </span>
        </template>

        <!-- Column: employee -->
        <template #cell(employee)="data">
          <div v-if="data.item.employee != null">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.employee.profile_image"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.employee.full_name }}
              </span>
              <small class="text-success font-small-3">{{
                data.item.employee.phone_number
              }}</small>
            </b-media>
          </div>
          <div v-else>
            <span>Employée supprimé</span>
          </div>

        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              v-if="directOrder.employee_contract_file_name !== '' && directOrder.actif_employee_id === data.item.id"
              :id="`open${data.item.id}`"
              icon="FileTextIcon"
              class="cursor-pointer mr-1"
              size="25"
              @click="openContractInNewTab(directOrder.employee_contract_file_name)"
            />
            <b-tooltip
              title="Contrat employé"
              class="cursor-pointer"
              :target="`open${data.item.id}`"
            />

            <feather-icon
              v-if="directOrder.status === 3 && directOrder.actif_employee_id === data.item.id"
              :id="`deploy${data.item.id}`"
              icon="NavigationIcon"
              class="cursor-pointer mr-1"
              size="25"
              @click="deployEmployee(data.item)"
            />
            <b-tooltip
              title="Déployer"
              class="cursor-pointer"
              :target="`deploy${data.item.id}`"
            />

            <feather-icon
              :id="`edit${data.item.id}`"
              v-b-modal.modal-modify-proposition
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="25"
              @click="getProposition(data.item)"
            />
            <b-tooltip
              title="Modifier cette proposition"
              class="cursor-pointer"
              :target="`edit${data.item.id}`"
            />

            <feather-icon
              v-if="directOrder.status === 1"
              :id="`delete${data.item.id}`"
              icon="TrashIcon"
              class="cursor-pointer text-danger mr-1"
              size="25"
              @click="applyDeleteDirectOrderPropositionAction(directOrder.id, data.item.employee_id)"
            />
            <b-tooltip
              title="Supprimer cette proposition"
              class="cursor-pointer"
              :target="`delete${data.item.id}`"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>

</template>

<script>
import {

  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BForm,
  BFormGroup,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BFormSelect,
  BSpinner,
  BCardBody,
  // BPagination,
  BTooltip, BCard, BTab, BTableLite, BTabs, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import ListOfTerminatedEmployees from './TerminatedEmployees.vue'

export default {
  name: 'ProposedEmployees',
  components: {
    BCard,
    BTab,
    BTableLite,
    BTabs,
    // BFormTextarea,
    BFormSelect,
    BSpinner,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCardHeader,
    BCardBody,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    // BPagination,
    BTooltip,
    vSelect,
    flatPickr,
    ListOfTerminatedEmployees,
  },
  props: {
    directOrder: {
      type: Object,
      required: true,
    },
    propositions: {
      type: Array,
      required: true,
    },
    serviceEmployees: {
      type: Array,
    },
    managedEmployee: {
      type: Array,
    },
  },
  data() {
    return {
      required,
      propositions: [],
      history: [],
      alphaNum,
      isMakingPropositionLoading: false,
      isProposedEmployeesLoading: false,
      isPaymentHistoryLoading: false,
      customerBudget: this.$props.directOrder.budget,
      employee_id: '',
      payload: {
        salary: '',
        employee_amount: '',
        ylomi_amount: '',
        percentage: '',
        search_employee: '',
        employee_id: '',
      },
      employee_amount: null,
      selectedEmployee: {},
      changingEmployee: {
        salary: '',
        employee_id: '',
      },
      currentProposition: {},
      tableColumns: [
        {
          key: 'employee', label: 'Employé', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'salary', label: 'Salaire', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'commission', label: 'Commission', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'total_amount', label: 'Total', sortable: true, class: 'font-medium-1',
        },
        {
          key: 'is_rejected', label: 'Statut', sortable: true, class: 'font-medium-1',
        },
        { key: 'actions', class: 'font-medium-1' },
      ],

      avatarText,

      isTerminateEmployeeContratLoading: false,
      deployEmployeePayload: {
        employee_deployment_date: null,
      },
      isDeployEmployeeLoading: false,
      currentDate: null,
      isPageLoading: true,
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
    taux() {
      if (this.ylomi_amount && this.employee_amount) {
        return ((this.ylomi_amount / this.employee_amount) * 100).toFixed(2)
      }
      return 0
    },
    ylomi_amount() {
      if (this.employee_amount) {
        return this.customerBudget - this.employee_amount
      }
      return 0
    },

  },

  watch: {
    selectedEmployee(val) {
      this.employee_amount = val.employee_desired_salary
    },
  },

  methods: {
    ...mapActions('services', ['getServiceEmployeesAction']),

    ...mapActions('orders', [
      'makeEmployeeDeploymentAction',
      'terminateEmployeeContractAction',
      'markDirectOrderAsFinishedAction',
      'deleteDirectOrderPropositionAction',
      'makePropositionAction',
      'deleteDirectOrderAction',
      'getDirectOrderAction',
      'getDirectOrderProposedEmployeesAction',
      'getDirectOrdersAction',
      'modifyPropositionAction',
    ]),

    deployEmployee(item) {
      if (!item.employee.is_formed) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Attention',
              icon: 'AlertCircleIcon',
              text: 'Employé non formé. Merci de réessayer après la formation de l\'employé',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.$bvModal.show('modal-deploy-employee')
      }
    },
    getProposition(item) {
      console.log(item, item.id)
      this.selectedEmployee = item.employee
      this.currentProposition = item
      this.customerBudget = item.salary
      this.employee_amount = item.employee_amount
      this.ylomi_amount = item.ylomi_amount
      this.taux = item.percentage
    },

    applyGetDirectOrderProposedEmployeesAction() {
      this.isProposedEmployeesLoading = true
      this.getDirectOrderProposedEmployeesAction(this.directOrder.id)
        .then(response => {
          this.propositions = response.data
          this.isProposedEmployeesLoading = false
        })
        .catch(error => {
          this.isProposedEmployeesLoading = false

          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDeleteDirectOrderPropositionAction(directOrderId, employeeId) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette proposition ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderPropositionAction({
            directOrderId,
            employeeId,
          })
          // eslint-disable-next-line no-return-await
          return await this.applyGetDirectOrderProposedEmployeesAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Proposition supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyModifyPropositionAction() {
      console.log(this.selectedEmployee)
      this.$refs.modifyPropositionForm.validate().then(success => {
        if (success) {
          this.isMakingPropositionLoading = true

          this.changingEmployee.employee_id = this.selectedEmployee.id
          this.changingEmployee.salary = this.customerBudget
          this.changingEmployee.employee_amount = this.employee_amount
          this.changingEmployee.ylomi_amount = this.ylomi_amount
          this.changingEmployee.percentage = this.taux

          this.modifyPropositionAction({
            payload: this.changingEmployee,
            id: this.directOrder.id,
            proposedEmployeeId: this.currentProposition.id,
          })
            .then(() => {
              this.getDirectOrderAction(this.directOrder.id).then(
                async response => {
                  this.directOrder = response.data
                  localStorage.setItem(
                    'directOrder',
                    JSON.stringify(this.directOrder),
                  )
                  this.isMakingPropositionLoading = false
                  await this.applyGetDirectOrderProposedEmployeesAction()
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Succès',
                        icon: 'CheckIcon',
                        text: "Proposition d'employée modifié avec succès.",
                        variant: 'success',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )

                  this.$bvModal.hide('modal-modify-proposition')
                  this.changingEmployee = {
                    salary: '',
                    employee_id: '',
                  }
                },
              )
              this.getDirectOrdersAction()
            })
            .catch(error => {
              // console.log(error)
              this.isMakingPropositionLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },
    showEmployeePropositionModal() {
      console.log(this.directOrder)
      if (this.directOrder.status === -1 && this.directOrder.direct_salary_payments.find(el => el.status === 0)) {
        this.$swal({
          title: 'Attention!',
          text: 'Impossible de faire une  proposition. Cette commande résilié à un paiement non payé.·',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.$router.push({ name: 'login' })
            }
          })
      } else {
        this.$bvModal.show('modal-new-proposition')
      }
    },
    applyMakePropositionAction() {
      this.$refs.newPropositionForm.validate().then(success => {
        if (success) {
          this.isMakingPropositionLoading = true
          this.payload.search_employee = this.directOrder.search_employee
            ? 1
            : 0
          this.payload.salary = this.customerBudget
          this.payload.employee_amount = this.employee_amount
          this.payload.percentage = this.taux
          this.payload.ylomi_amount = this.ylomi_amount
          this.payload.employee_id = this.selectedEmployee.id
          this.makePropositionAction({
            payload: this.payload,
            id: this.directOrder.id,
          })
            .then(() => {
              this.getDirectOrderAction(this.directOrder.id).then(
                async response => {
                  this.directOrder = response.data
                  localStorage.setItem(
                    'directOrder',
                    JSON.stringify(this.directOrder),
                  )
                  this.isMakingPropositionLoading = false
                  await this.applyGetDirectOrderProposedEmployeesAction()
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Succès',
                        icon: 'CheckIcon',
                        text: 'Employé proposé avec succès',
                        variant: 'success',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )

                  this.$bvModal.hide('modal-new-proposition')
                  this.$bvModal.hide('modal-new-proposition-2')
                  this.payload = {
                    salary: '',
                    search_employee: '',
                    employee_id: '',
                  }
                },
              )
              this.getDirectOrdersAction()
            })
            .catch(error => {
              // console.log(error)
              this.isMakingPropositionLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },

    applyDeployEmployeeContractAction() {
      this.isDeployEmployeeLoading = true
      this.$swal({
        title: "Déployement d'employé",
        text: 'Êtes-vous sûr de vouloir déployer cet employé ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.makeEmployeeDeploymentAction({
          orderId: this.directOrder.id,
          payload: this.deployEmployeePayload,
        }),
      })
        .then(response => {
          this.isDeployEmployeeLoading = false
          console.log(response)
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.getDirectOrderAction(this.directOrder.id)
              .then(result => {
                localStorage.setItem('directOrder', JSON.stringify(result.data))
                this.$bvModal.hide('modal-deploy-employee')
                this.$router.go()
              })
          }
        })
        .catch(error => {
          this.isDeployEmployeeLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyTerminateEmployeeContractAction() {
      this.isTerminateEmployeeContratLoading = true
      this.$swal({
        title: 'Résilier le contrat employé',
        text: "Êtes-vous sûr de vouloir résilier ce contrat de l'employé actif ?",
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.terminateEmployeeContractAction({
          orderId: this.directOrder.id,
          payload: this.terminateEmployeePayload,
        }),
      })
        .then(response => {
          this.isTerminateEmployeeContratLoading = false
          // console.log(response)
          if (response.value) {
            this.directOrder = response.value.data
            this.applyGetDirectOrderProposedEmployeesAction()
            localStorage.setItem(
              'directOrder',
              JSON.stringify(this.directOrder),
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$bvModal.hide('modal-terminate-employee-contrat')
            this.getDirectOrdersAction()
          }
        })
        .catch(error => {
          this.isTerminateEmployeeContratLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyMarkDirectOrderAsFinished() {
      this.$swal({
        title: 'Clôturer le dossier',
        text: 'Êtes-vous sûr de vouloir clôturer le dossier ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.markDirectOrderAsFinishedAction(this.directOrder.id),
      })
        .then(response => {
          console.log(response)
          if (response.isConfirmed) {
            this.directOrder = response.value.data
            localStorage.setItem(
              'directOrder',
              JSON.stringify(this.directOrder),
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Contrat résilié',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.getDirectOrdersAction()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    resolveOfferStatusVariant(employeeId) {
      if (this.directOrder.actif_employee_id != null) {
        if (employeeId === this.directOrder.actif_employee_id) return 'success'
        return 'info'
      }
      return 'info'
    },

    resolveOfferStatusVariantForHistory(historyStatus) {
      if (historyStatus === 1) {
        return 'success'
      }
      return 'info'
    },

    resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'danger'
      if (status === 0 && placementCostPaid === false && searchEmployee === true) return 'danger'
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },

    resolveStatusText: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'Résilié'
      if (status === 0 && placementCostPaid === false && searchEmployee === true) return 'En attente des frais de placement'
      if (status === 0) return 'En attente'
      if (status === 1) return 'Employé proposé'
      if (status === 2) return 'Contract émis'
      if (status === 3) return 'Contract approuvé'
      if (status === 4) return 'Actif'
      return 'Terminée'
    },
    hideModal() {
      this.$bvModal.hide('modal-new-proposition')
      this.payload = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
    hideModal2() {
      this.$bvModal.hide('modal-new-proposition-2')
      this.payload = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
    hidePropositionModificationModal() {
      this.$bvModal.hide('modal-modify-proposition')
      this.changingEmployee = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
    openContractInNewTab(contractFile) {
      window.open(`${contractFile}`)
    },
  },
}
</script>

<style scoped>
</style>
