<template>
  <div
    v-if="isPageLoading"
    class="d-flex justify-content-center mb-1"
    style="padding-top: 30vh"
  >
    <b-spinner
      variant="info"
      style="width: 3rem; height: 3rem"
      class="mr-1"
    />
  </div>
  <div
    v-else-if="orderNotFound"
    class="text-center mt-5"
  >
    <h1 class="text-danger">
      <feather-icon
        icon="XCircleIcon"
        size="100"
      />
    </h1>
    <p class="font-medium-1 text-danger font-weight-normal">
      Aucune commande ne correspond à cet identifiant
    </p>
  </div>
  <div v-else>
    <order-details :direct-order="directOrder" />
    <!--<b-tabs
      vertical
      content-class="col-12 col-md-10 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-2 col-12"
      nav-class="nav-left"
    >-->
    <!--<b-tab active>
        <template #title>
          <feather-icon icon="ListIcon" size="15" />
          <span class="font-medium-1 font-weight-normal">Détails</span>
        </template>
        <order-details :direct-order="directOrder" />
      </b-tab>-->

    <!--<b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" size="15"/>
          <span class="font-medium-1 font-weight-normal">Propositions</span>
        </template>
        <proposed-employees :managed-employee="managedEmployee" :service-employees="employeeOptions"
                            :direct-order="directOrder" :propositions="propositions"/>
      </b-tab>-->

    <!--<b-tab>
        <template #title>
          <feather-icon icon="XCircleIcon" size="15" />
          <span class="font-medium-1 font-weight-normal">Désistements</span>
        </template>
        <terminated-employees :order-id="directOrder.id" />
      </b-tab>-->

    <!--<b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" size="15" />
          <span class="font-medium-1 font-weight-normal">Paiements</span>
        </template>
        <payment-logs :logs="history" />
      </b-tab>-->
    <!--/ recurring orders tab -->
    <!--</b-tabs>-->
  </div>
</template>

<script>
import {
  BTabs, BTab, BSpinner, BAlert,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { alphaNum, required } from '@core/utils/validations/validations'
import ProposedEmployees from '@/views/order/recurring/details/ProposedEmployees'
import OrderDetails from './index'
import TerminatedEmployees from './TerminatedEmployees.vue'
import PaymentLogs from '@/views/order/recurring/details/PaymentLogs'

export default {
  name: 'Main',
  components: {
    BTabs,
    BTab,
    BSpinner,
    BAlert,
    OrderDetails,
    ProposedEmployees,
    TerminatedEmployees,
    PaymentLogs,
  },
  data() {
    return {
      directOrder: null,
      required,
      orderNotFound: false,
      propositions: [],
      employeeOptions: [],
      managedEmployee: [],
      history: [],
      isPageLoading: true,
    }
  },
  created() {
    this.getDirectOrderAction(this.$route.params.id)
      .then(response => {
        this.directOrder = response.data
        this.isPageLoading = false
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          this.orderNotFound = true
          this.isPageLoading = false
        }
      })
  },

  methods: {
    ...mapActions('orders', [
      'makeEmployeeDeploymentAction',
      'terminateEmployeeContractAction',
      'markDirectOrderAsFinishedAction',
      'deleteDirectOrderPropositionAction',
      'makePropositionAction',
      'deleteDirectOrderAction',
      'getDirectOrderAction',
      'getDirectOrderProposedEmployeesAction',
      'getDirectOrdersAction',
      'modifyPropositionAction',
      'getRecommendedEmployeesAction',
    ]),

    getProposition(item) {
      // eslint-disable-next-line no-multi-assign
      console.log(item, item.id)
      this.selectedEmployee = item.employee
      this.currentProposition = item
      // eslint-disable-next-line prefer-destructuring
      const percentage = this.directOrder.offer_type.percentage
      this.changingEmployee.salary = Math.round(
        item.salary / (1 + percentage / 100),
      )
    },

    modifyOrder(id) {
      this.$router.push({
        name: 'admin-direct-order-modify',
        params: { id },
      })
    },

    sendPaiementLinkToCustoomer(id, employeeName) {
      window.open(
        `https://wa.me/${this.directOrder.user.phone_number}?text=Nos salutations! ${this.directOrder.user.first_name}!  La date du  paiement de la prestation de votre ${this.directOrder.recurring_service.name} *${employeeName}* étant aujourdhui, merci de cliquer sur lien ci dessous pour effectuer le paiement. *https://ylomi.net/direct/${this.directOrder.id}/payment/${id}*`,
      )
    },

    applyGetRecommendedEmployeesAction(id) {
      this.getRecommendedEmployeesAction(id).then(response => {
        // console.log(response)
        if (response.data.length === 0) {
          this.employeeOptions.push({
            value: '',
            disabled: true,
            text: "Aucune recommandation n'est effectué",
          })
        } else {
          response.data.forEach(element => {
            if (
              element.phone_number === this.directOrder.employee_phone_number
            ) {
              this.managedEmployee.push(element)
              this.payload.salary = this.directOrder.budget
            }
            this.employeeOptions.push({
              comment: element.comment,
              full_name: element.employee.full_name,
              id: element.employee.id,
              employee_desired_salary: element.employee_desired_salary,
              employee: element.employee,
            })
          })
        }
      })
    },

    applyGetDirectOrderProposedEmployeesAction() {
      this.isProposedEmployeesLoading = true
      this.getDirectOrderProposedEmployeesAction(this.directOrder.id)
        .then(response => {
          this.propositions = response.data
          this.isProposedEmployeesLoading = false
        })
        .catch(error => {
          this.isProposedEmployeesLoading = false

          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDeleteDirectOrderAction() {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette demande?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderAction(this.directOrder.id)
          // eslint-disable-next-line no-return-await
          return await this.getDirectOrdersAction()
        },
      })
        .then(response => {
          if (response.value) {
            localStorage.removeItem('directOrder')
            this.$router.push({ name: 'admin-direct-orders' })
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'demande supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyDeleteDirectOrderPropositionAction(directOrderId, employeeId) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cette proposition ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        preConfirm: async () => {
          await this.deleteDirectOrderPropositionAction({
            directOrderId,
            employeeId,
          })
          // eslint-disable-next-line no-return-await
          return await this.applyGetDirectOrderProposedEmployeesAction()
        },
      })
        .then(response => {
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Offre supprimée',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyModifyPropositionAction() {
      console.log(this.selectedEmployee)
      this.$refs.modifyPropositionForm.validate().then(success => {
        if (success) {
          this.isMakingPropositionLoading = true
          /* this.changingEmployee.search_employee = this.directOrder.search_employee
            ? 1
            : 0 */
          // eslint-disable-next-line eqeqeq
          if (this.directOrder.search_employee == 1) {
            const comission = this.directOrder.offer_type.name === 'Offre confort'
              ? // eslint-disable-next-line radix
              (parseInt(this.changingEmployee.salary) * 10) / 100
              : // eslint-disable-next-line radix
              parseInt(this.changingEmployee.salary * 15) / 100
            // eslint-disable-next-line radix
            this.changingEmployee.salary = Math.round(
              parseInt(this.changingEmployee.salary) + comission,
            )
          }
          this.changingEmployee.employee_id = this.selectedEmployee.id

          console.log({
            payload: this.changingEmployee,
            id: this.directOrder.id,
            proposedEmployeeId: this.currentProposition.id,
          })

          this.modifyPropositionAction({
            payload: this.changingEmployee,
            id: this.directOrder.id,
            proposedEmployeeId: this.currentProposition.id,
          })
            .then(() => {
              this.getDirectOrderAction(this.directOrder.id).then(
                async response => {
                  this.directOrder = response.data
                  localStorage.setItem(
                    'directOrder',
                    JSON.stringify(this.directOrder),
                  )
                  this.isMakingPropositionLoading = false
                  await this.applyGetDirectOrderProposedEmployeesAction()
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Succès',
                        icon: 'CheckIcon',
                        text: "Proposition d'employée modifié avec succès.",
                        variant: 'success',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )

                  this.$bvModal.hide('modal-modify-proposition')
                  this.changingEmployee = {
                    salary: '',
                    employee_id: '',
                  }
                },
              )
              this.getDirectOrdersAction()
            })
            .catch(error => {
              // console.log(error)
              this.isMakingPropositionLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },

    applyMakePropositionAction() {
      this.$refs.newPropositionForm.validate().then(success => {
        if (success) {
          this.isMakingPropositionLoading = true
          this.payload.search_employee = this.directOrder.search_employee
            ? 1
            : 0
          // eslint-disable-next-line eqeqeq
          if (this.directOrder.search_employee == 1) {
            const comission = this.directOrder.offer_type.name === 'Offre confort'
              ? // eslint-disable-next-line radix
              (parseInt(this.payload.salary) * 10) / 100
              : // eslint-disable-next-line radix
              parseInt(this.payload.salary * 15) / 100
            // eslint-disable-next-line radix
            this.payload.salary = Math.round(
              parseInt(this.payload.salary) + comission,
            )
          }

          this.makePropositionAction({
            payload: this.payload,
            id: this.directOrder.id,
          })
            .then(() => {
              this.getDirectOrderAction(this.directOrder.id).then(
                async response => {
                  this.directOrder = response.data
                  localStorage.setItem(
                    'directOrder',
                    JSON.stringify(this.directOrder),
                  )
                  this.isMakingPropositionLoading = false
                  await this.applyGetDirectOrderProposedEmployeesAction()
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Succès',
                        icon: 'CheckIcon',
                        text: 'Employé proposé avec succès',
                        variant: 'success',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )

                  this.$bvModal.hide('modal-new-proposition')
                  this.$bvModal.hide('modal-new-proposition-2')
                  this.payload = {
                    salary: '',
                    search_employee: '',
                    employee_id: '',
                  }
                },
              )
              this.getDirectOrdersAction()
            })
            .catch(error => {
              // console.log(error)
              this.isMakingPropositionLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },

    applyDeployEmployeeContractAction() {
      this.isDeployEmployeeLoading = true
      this.$swal({
        title: "Déployement d'employé",
        text: 'Êtes-vous sûr de vouloir déployer cet employé ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.makeEmployeeDeploymentAction({
          orderId: this.directOrder.id,
          payload: this.deployEmployeePayload,
        }),
      })
        .then(response => {
          this.isDeployEmployeeLoading = false
          console.log(response)
          if (response.value) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.getDirectOrderAction(this.directOrder.id).then(result => {
              localStorage.setItem('directOrder', JSON.stringify(result.data))
              this.$bvModal.hide('modal-deploy-employee')
              this.$router.go()
            })
          }
        })
        .catch(error => {
          this.isDeployEmployeeLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyTerminateEmployeeContractAction() {
      this.isTerminateEmployeeContratLoading = true
      this.$swal({
        title: 'Résilier le contrat employé',
        text: "Êtes-vous sûr de vouloir résilier ce contrat de l'employé actif ?",
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.terminateEmployeeContractAction({
          orderId: this.directOrder.id,
          payload: this.terminateEmployeePayload,
        }),
      })
        .then(response => {
          this.isTerminateEmployeeContratLoading = false
          // console.log(response)
          if (response.value) {
            this.directOrder = response.value.data
            this.applyGetDirectOrderProposedEmployeesAction()
            localStorage.setItem(
              'directOrder',
              JSON.stringify(this.directOrder),
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$bvModal.hide('modal-terminate-employee-contrat')
            this.getDirectOrdersAction()
          }
        })
        .catch(error => {
          this.isTerminateEmployeeContratLoading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    applyMarkDirectOrderAsFinished() {
      this.$swal({
        title: 'Clôturer le dossier',
        text: 'Êtes-vous sûr de vouloir clôturer le dossier ?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.markDirectOrderAsFinishedAction(this.directOrder.id),
      })
        .then(response => {
          console.log(response)
          if (response.isConfirmed) {
            this.directOrder = response.value.data
            localStorage.setItem(
              'directOrder',
              JSON.stringify(this.directOrder),
            )
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Contrat résilié',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
          this.getDirectOrdersAction()
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    resolveOfferStatusVariant(employeeId) {
      if (this.directOrder.actif_employee_id != null) {
        if (employeeId === this.directOrder.actif_employee_id) return 'success'
        return 'info'
      }
      return 'info'
    },

    resolveOfferStatusVariantForHistory(historyStatus) {
      if (historyStatus === 1) {
        return 'success'
      }
      return 'info'
    },

    resolveOrderStatusVariant: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'danger'
      if (
        status === 0
        && placementCostPaid === false
        && searchEmployee === true
      ) { return 'danger' }
      if (status === 0) return 'warning'
      if (status === 1) return 'info'
      if (status === 2) return 'warning'
      if (status === 3 || status === 4) return 'success'
      return 'primary'
    },

    resolveStatusText: (status, placementCostPaid, searchEmployee) => {
      if (status === -1) return 'Résilié'
      if (
        status === 0
        && placementCostPaid === false
        && searchEmployee === true
      ) { return 'En attente des frais de placement' }
      if (status === 0) return 'En attente'
      if (status === 1) return 'Employé proposé'
      if (status === 2) return 'Contract émis'
      if (status === 3) return 'Contract approuvé'
      if (status === 4) return 'Actif'
      return 'Terminée'
    },
    hideModal() {
      this.$bvModal.hide('modal-new-proposition')
      this.payload = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
    hideModal2() {
      this.$bvModal.hide('modal-new-proposition-2')
      this.payload = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
    hidePropositionModificationModal() {
      this.$bvModal.hide('modal-modify-proposition')
      this.changingEmployee = {
        salary: '',
        search_employee: '',
        employee_id: '',
      }
    },
  },
}
</script>

<style scoped>
</style>
